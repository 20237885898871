<template>
  <b-row class="border-top rounded pt-1">
    <b-col sm="4">
      <app-input
        id="priceExcludingTax"
        v-model.number="value[0].sellingPriceExcludingTaxes"
        :label="$t('service.prices.excluding_taxes')"
        :append="getCurrency()"
        :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
        rules="required|positive"
        type="number"
        step="0.01"
        min="0"
      />
    </b-col>

    <b-col sm="4">
      <app-input
        id="priceIncludingTax"
        :value="setTTC(value[0])"
        :label="$t('service.prices.including_taxes')"
        :append="getCurrency()"
        disabled
      />
    </b-col>

    <b-col sm="4">
      <app-input
        id="priceEcoTax"
        v-model.number="value[0].ecologicalTax"
        :label="$t('service.prices.eco_tax')"
        :append="getCurrency()"
        rules="required|positive"
        type="number"
        :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
        step="0.01"
        min="0"
      />
    </b-col>
  </b-row>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'
import getCurrency from '@/helpers/getCurrency'

export default defineComponent({
  name: 'PriceUnity',

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const product = inject('product')

    const setTTC = price => Math.round((price.sellingPriceExcludingTaxes
      + (price.sellingPriceExcludingTaxes * product.value.mainVariant.vatRate) / 100) * 100) / 100

    return {
      product,
      setTTC,
      getCurrency,
    }
  },
})
</script>
