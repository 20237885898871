<template>
  <b-card class="mb-0 h-100">
    <div class="d-flex justify-content-between">
      <h2 class="d-inline">{{ $t('service.price_management') }}</h2>
      <app-checkbox-calendar
        id="calendar-mode"
        v-model="product.mainVariant.calendarMode"
        :label="$t('service.calendar.title')"
        @change="switchCalendar()"
      />
    </div>

    <validation-observer
      v-if="product"
      ref="priceManagementRef"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <b-row :class="['d-flex align-items-center mt-2', {'mb-3 pb-3': !pricingCustom}]">
        <b-col cols="12">
          <b-form-checkbox
            v-model="pricingCustom"
            name="pricing-custom"
            :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
            switch
            inline
            @change="() => {
              switchPrices()
              product.mainVariant.visible = pricingCustom
            }"
          >
            {{ $t('service.prices.custom') }}
          </b-form-checkbox>
          <b-form-checkbox
            v-if="pricingCustom"
            v-model="product.mainVariant.visible"
            name="visible"
            :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'"
            switch
            inline
          >
            {{ $t('service.prices.visible') }}
          </b-form-checkbox>
        </b-col>
        <span v-if="pricingCustom" style="font-size: 12px; margin-left: 150px" class="pl-1"><i>{{ $t('service.prices.custom_note') }}</i></span>
      </b-row>

      <div v-if="!product.mainVariant.calendarMode">
        <price-global-fields v-if="pricingCustom" class="mt-2" />

        <b-tabs v-if="pricingCustom && isHangars" align="left" nav-class="mb-0">
          <b-tab
            v-for="period in periods"
            :id="period.tab"
            :key="period.tab"
            :active="period.tab === tabActive"
            class="tab"
            @click="currentPeriod = period.tab"
          >
            <template #title>
              <div class="title">
                <span>{{ period.label }}</span>
              </div>
            </template>

            <price-types
              :key="currentKey"
              :tab-id="currentPeriod"
              :value="product.mainVariant.prices.filter(price => price.periodicity === currentPeriod)"
              @input="event => {
                const othersPeriodsPrices = product.mainVariant.prices.filter(price => price.periodicity !== currentPeriod)
                product.mainVariant.prices = othersPeriodsPrices.concat(event)
              }"
            />
          </b-tab>
        </b-tabs>

        <!-- SELECTOR TYPE PRICE -->
        <price-types
          v-else
          v-show="pricingCustom"
          :key="currentKey"
          v-model="product.mainVariant.prices"
        />
<!--        <footer-form-service v-if="$route.name === 'productShow' || $route.name === 'operatorProductShow'" previous next @click:next="$emit('next-tab')" @click:previous="$emit('previous-tab')" />
        <footer-form-service v-else previous save save-next @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />
     --> </div>
      <div v-else>
<!--        <footer-form-service save :back-to-list="false" />-->
      </div>

      <availability-prices v-if="product && product.mainVariant.calendarMode && pricingCustom" @update-calendar="switchPrices()" v-on="$listeners" />

      <footer-form-service v-if="$route.name === 'productShow' || $route.name === 'operatorProductShow'" previous next @click:next="$emit('next-tab')" @click:previous="$emit('previous-tab')" />
      <footer-form-service v-else previous save save-next @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />
    </validation-observer>
  </b-card>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'

import AppCheckboxCalendar from '@/components/AppCheckboxCalendar.vue'
import AvailabilityPrices from '../component/AvailabilityPrices.vue'
import PriceTypes from '../component/PriceTypes.vue'
import FooterFormService from '../component/FooterFormService.vue'
import PriceGlobalFields from '../component/PriceGlobalFields.vue'

export default defineComponent({
  name: 'PriceManagement',

  components: {
    PriceTypes,
    AppCheckboxCalendar,
    FooterFormService,
    AvailabilityPrices,
    PriceGlobalFields,
  },

  setup(_props, ctx) {
    const { $i18n, toaster, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const priceManagementRef = ref(null)

    const pricingCustomInitial = ref(false)
    const productVisibleInitial = ref(false)
    const pricingCustom = ref(false)

    const nextRedirection = ref(false)

    const product = inject('product')

    const currentKey = ref(0)
    const oldPrices = ref([])
    const oldRangePrices = ref([])
    const isHangars = ['hangar', 'parking', 'outside_ramps'].includes(product.value.typology)

    const switchPrices = () => {
      // Si le pricingCustom est à false, on reset les prix fixes et calendaires
      if (!pricingCustom.value) {
        product.value.mainVariant.prices = []
        product.value.mainVariant.priceRanges = []
        return
      }

      if (product.value.mainVariant.calendarMode) {
        // Passage en mode calendrier, donc sauvegarde des prix fixes et reset des prix fixes
        oldPrices.value = _cloneDeep(product.value.mainVariant.prices)
        product.value.mainVariant.prices = []

        // Si des anciens prix calendaires existaient, on les remets dans le tableau
        // Sinon, on copie les prix calendaire de la référence du produit
        if (oldRangePrices.value.length) {
          product.value.mainVariant.priceRanges = _cloneDeep(oldRangePrices.value)
        } else {
          product.value.mainVariant.priceRanges = _cloneDeep(product.value._reference.mainVariant.priceRanges)
        }
      } else {
        // Passage en mode prix fixe, donc on sauvegarde les prix calendaires et on reset les prix calendaires
        oldRangePrices.value = _cloneDeep(product.value.mainVariant.priceRanges)
        product.value.mainVariant.priceRanges = []

        // Si des anciens prix fixes existaient, on les remets dans le tableau
        // Sinon, on copie les prix fixes de la référence du produit
        if (oldPrices.value.length) {
          product.value.mainVariant.prices = _cloneDeep(oldPrices.value)
        } else {
          product.value.mainVariant.prices = _cloneDeep(product.value._reference.mainVariant.prices)

          // Si aucun prix n'existe, on ajoute un prix par défaut
          const initPrice = {
            id: null,
            fromQuantity: 1,
            toQuantity: null,
            fromQuantityUnit: 'hour',
            toQuantityUnit: 'hour',
            sellingPriceExcludingTaxes: 0,
            sellingPriceIncludingTaxes: 0,
            ecologicalTax: 0,
            packingQuantity: 1,
          }
          if (!isHangars) {
            if (!product.value.mainVariant.prices.length) {
              product.value.mainVariant.prices.push(initPrice)
            }
          } else {
            let periodicities = ['daily', 'weekly', 'monthly', 'annual']
            product.value.mainVariant.prices.forEach(price => {
              if (periodicities.includes(price.periodicity)) {
                periodicities = periodicities.filter(period => price.periodicity !== period)
              }
            })
            periodicities.forEach(period => {
              product.value.mainVariant.prices.push({ ...initPrice, periodicity: period })
            })
          }
        }
      }

      currentKey.value += 1
    }

    const switchPrices2 = () => {
      if (pricingCustom.value) {
        if (oldRangePrices.value.length) {
          product.value.mainVariant.priceRanges = _cloneDeep(oldRangePrices.value)
        } else {
          product.value.mainVariant.priceRanges = _cloneDeep(product.value._reference.mainVariant.priceRanges)
        }

        if (oldPrices.value.length) {
          product.value.mainVariant.prices = _cloneDeep(oldPrices.value)
        } else {
          product.value.mainVariant.prices = _cloneDeep(product.value._reference.mainVariant.prices)

          const initPrice = {
            id: null,
            fromQuantity: 1,
            toQuantity: null,
            fromQuantityUnit: 'hour',
            toQuantityUnit: 'hour',
            sellingPriceExcludingTaxes: 0,
            sellingPriceIncludingTaxes: 0,
            ecologicalTax: 0,
            packingQuantity: 1,
          }
          if (!isHangars) {
            if (!product.value.mainVariant.prices.length) {
              product.value.mainVariant.prices.push(initPrice)
            }
          } else {
            let periodicities = ['daily', 'weekly', 'monthly', 'annual']
            product.value.mainVariant.prices.forEach(price => {
              if (periodicities.includes(price.periodicity)) {
                periodicities = periodicities.filter(period => price.periodicity !== period)
              }
            })
            periodicities.forEach(period => {
              product.value.mainVariant.prices.push({ ...initPrice, periodicity: period })
            })
          }
        }
      } else {
        oldPrices.value = _cloneDeep(product.value.mainVariant.prices)
        oldRangePrices.value = _cloneDeep(product.value.mainVariant.priceRanges)
        product.value.mainVariant.prices = []
        product.value.mainVariant.priceRanges = []
      }

      currentKey.value += 1
    }

    const switchCalendar = () => {
      switchPrices()
    }

    product.value.fetchPrice(ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(() => {
      pricingCustom.value = product?.value?.mainVariant?.prices?.length > 0 || product?.value?.mainVariant?.rangePrices?.length > 0
      switchPrices()
    })

    // HANGAR TYPES
    const tabActive = ref('dailyPrice')
    const currentPeriod = ref('daily')
    const periods = [
      { tab: 'daily', label: 'Day' },
      { tab: 'weekly', label: 'Week' },
      { tab: 'monthly', label: 'Month' },
      { tab: 'annual', label: 'Year' },
    ]

    const onSubmit = async () => {
      const validForm = await priceManagementRef.value.validate()
      if (validForm) {
        await product.value.patchPrice()

        // Si on est en mode calendrier, on reset les prix fixes sauvegardés, à l'inverse les prix calendaire
        if (product.value.mainVariant.calendarMode) {
          oldPrices.value = []
        } else {
          oldRangePrices.value = []
        }

        if (nextRedirection.value) {
          $emit('next-tab')
          nextRedirection.value = false
        }
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    }

    return {
      product,
      isHangars,
      priceManagementRef,
      nextRedirection,
      currentKey,
      oldPrices,
      oldRangePrices,
      pricingCustom,
      onSubmit,
      tabActive,
      currentPeriod,
      periods,
      switchPrices,
      switchCalendar,
    }
  },
})
</script>
