<template>
  <section ref="formBracket" class="repeater-form row-price">
    <!-- EACH PRICE -->
    <b-row v-for="(price, index) in value" :key="index + tabId" ref="rowSlicePrice" class="border-top rounded pt-1">
      <p v-if="['hangar', 'parking', 'outside_ramps'].includes(product.typology) && !product.mainVariant.calendarMode && !index" class="ml-1 w-100">
        {{ $t('service.prices.notice_periodic_slice') }}
      </p>

      <!-- FROM -->
      <b-col cols="6">
        <app-quantity-per-unit-input
          :id="`fromQuantity${index + 1}`"
          v-model.number="price.fromQuantity"
          :unit.sync="price.fromQuantityUnit"
          :units-type="product.mainVariant.packing.type"
          :label="upperFirst($t('common.from'))"
          :vv-name="`'${upperFirst($t('common.from'))} [${lowerCase($t('service.prices.slice'))}: ${index + 1}]'`"
          :rules="rulesFromQuantity(price, index)"
        />
      </b-col>

      <!-- TO -->
      <b-col cols="6">
        <app-quantity-per-unit-input
          :id="`toQuantity${index + 1}`"
          v-model.number="price.toQuantity"
          :unit.sync="price.toQuantityUnit"
          :units-type="product.mainVariant.packing.type"
          :label="upperFirst($t('common.to'))"
          :vv-name="`'${upperFirst($t('common.to'))} [${lowerCase($t('service.prices.slice'))}: ${index + 1}]'`"
          :rules="rulesToQuantity(price, index)"
        />
      </b-col>
      <b-col sm="4">
        <app-input
          id="priceHT"
          v-model.number="price.sellingPriceExcludingTaxes"
          :append="currency"
          :label="$t('service.prices.excluding_taxes')"
          :rules="!['hangar', 'parking', 'outside_ramps'].includes(product.typology) ? 'positive|required' : 'positive'"
          type="number"
          step="0.01"
          min="0"
        />
      </b-col>

      <b-col sm="4">
        <app-input
          id="priceTTC"
          :value="setTTC(price)"
          :append="currency"
          :label="$t('service.prices.including_taxes')"
          rules="positive|required"
          type="number"
          step="0.01"
          min="0"
          disabled
        />
      </b-col>

      <b-col sm="4">
        <app-input
          id="priceEcoTax"
          v-model.number="price.ecologicalTax"
          :append="currency"
          :label="$t('service.prices.eco_tax')"
          rules="positive|required"
          type="number"
          step="0.01"
          min="0"
        />
      </b-col>

      <!-- ADD REMOVE BUTTON -->
      <b-col class="d-flex" cols="1">
        <b-button-group>
          <b-button v-if="value.length > 1" v-ripple.400 variant="outline-primary" @click="modalDeleteItem(index)">
            <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="minus" class="text-danger" :title="$t('action.delete')" />
          </b-button>
          <b-button v-if="index === value.length - 1" v-ripple.400 variant="outline-primary" @click="repeatAgain">
            <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="plus" class="text-success" :title="$t('action.add')" />
          </b-button>
        </b-button-group>
      </b-col>
      <hr />
    </b-row>
  </section>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'
import { lowerCase, upperFirst } from 'lodash'
import getCurrency from '@/helpers/getCurrency'
import AppQuantityPerUnitInput from '@/components/AppQuantityPerUnitInput.vue'

export default defineComponent({
  name: 'PriceSlice',

  components: {
    AppQuantityPerUnitInput,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const { $i18n, $bvModal, alert, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')
    const currency = ref(getCurrency())

    const repeatAgain = () => {
      const newSlicePrice = _cloneDeep(props.value[props.value.length - 1])
      newSlicePrice.id = null
      newSlicePrice.fromQuantity = newSlicePrice.toQuantity
      newSlicePrice.toQuantity = parseFloat((newSlicePrice.toQuantity + props.value[0].toQuantity).toFixed(4))
      props.value.push(newSlicePrice)
      $emit('input', props.value)
    }

    const modalDeleteItem = index => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(isConfirmed => {
          if (isConfirmed) {
            alert($i18n.t('alert.delete.success'))
            props.value.splice(index, 1)
            $emit('input', props.value)
          }
        })
    }

    const rulesFromQuantity = (price, index) => {
      const rules = ['positive', 'required']
      const vidToQuantityPreviousSlice = `toQuantity${index}`
      const vidToQuantityCurrentSlice = `toQuantity${index + 1}`
      const isTypeHourPacking = product.value.mainVariant.packing.type === 'minutes'

      if (!isTypeHourPacking && index) rules.push(`min_value:@${vidToQuantityPreviousSlice}`)
      if (!isTypeHourPacking && price.toQuantity !== null) rules.push(`max_value:@${vidToQuantityCurrentSlice}`)
      return rules.join('|')
    }

    const rulesToQuantity = (price, index) => {
      const rules = ['positive']
      const vidFromQuantityCurrentSlice = `fromQuantity${index + 1}`
      const vidFromQuantityNextSlice = `fromQuantity${index + 2}`
      const isTypeHourPacking = product.value.mainVariant.packing.type === 'minutes'

      if (!isTypeHourPacking && price.toQuantity !== null) rules.push(`min_value:@${vidFromQuantityCurrentSlice}`)
      if (!isTypeHourPacking && index + 1 !== props.value.length) {
        rules.push(`max_value:@${vidFromQuantityNextSlice}`, 'required')
      }
      return rules.join('|')
    }

    const setTTC = price => Math.round((price.sellingPriceExcludingTaxes + (price.sellingPriceExcludingTaxes * product.value.mainVariant.vatRate) / 100) * 100) / 100

    return {
      product,
      currency,
      repeatAgain,
      modalDeleteItem,
      rulesFromQuantity,
      rulesToQuantity,
      setTTC,
      lowerCase,
      upperFirst,
    }
  },
})
</script>
