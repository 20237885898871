<template>
  <section>
    <validation-observer ref="formCalendar" tag="form" @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12" class="mb-2">
          <app-input id="title" v-model="priceRange.title" label="Title" autofocus required />
        </b-col>

        <b-col cols="6" class="mb-1">
          <validation-provider #default="{ errors }" name="Start date" rules="required" class="validation-required mb-1">
            <b-form-group label="Start Date" label-for="start-date">
              <b-form-datepicker
                v-model="priceRange.startAt"
                class="mb-0"
                :placeholder="$t('common.start_at')"
                :locale="$i18n.locale"
                :max="priceRange.endAt"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Repeat" label-for="repeat">
            <b-form-select
              v-model="dateRepeat"
              :options="dateRepeatOptions"
              @input="priceRange.daysOfWeek = dateRepeat === 'everyDays' ? [0, 1, 2, 3, 4, 5, 6] : []"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="dateRepeat === 'custom'" cols="12">
          <b-form-group class="mb-2">
            <b-form-checkbox-group
              v-model="priceRange.daysOfWeek"
              class="d-flex flex-wrap"
              button-variant="outline-primary"
              :options="daysOfWeekOptions"
              buttons
              group-id="radios-btn-default"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <div class="d-flex align-items-center w-100">
            <b-form-radio v-model="endConfig" class="mr-1" value="never" group-id="some-radios" @change="priceRange.endAt = null">
              {{ $t('service.calendar.occurrence.never') }}
            </b-form-radio>

            <b-form-radio v-model="endConfig" value="end" group-id="end" />
            <b-form-datepicker
              v-model="priceRange.endAt"
              :placeholder="$t('common.end_at')"
              :locale="$i18n.locale"
              :min="priceRange.startAt"
              @input="endConfig = 'end'"
            />
          </div>
        </b-col>

        <b-col cols="7" sm="6" md="4" class="d-flex align-items-center mt-1 mt-md-0">
          <b-form-radio v-model="endConfig" value="occurrence" group-id="occurrence" class="mr-1 mr-sm-4 mr-md-2">
            {{ $t('service.calendar.occurrence.after') }}
          </b-form-radio>

          <app-input
            id="weeksOccurrence"
            v-model.number="weeksOccurrence"
            :style-group="{ width: '100px' }"
            rule="positive"
            type="number"
            @input="
              val => {
                if (!val || val < 0) weeksOccurrence = 0
                endConfig = 'occurrence'
                priceRange.endAt = $moment(priceRange.startAt).add(weeksOccurrence, 'weeks').format('YYYY-MM-DD')
              }
            "
          />
          <span class="ml-1">{{ $tc('service.calendar.occurrence.title', weeksOccurrence) }}</span>
        </b-col>

        <!-- All Day -->
        <b-col v-if="!isHangars" class="d-flex align-items-center justify-content-end justify-content-sm-start mt-1 mt-md-0 pr-0">
          <b-form-checkbox v-model="dateConfig.allDay" group-id="check-button" switch inline @change="switchAllDay($event)">
            {{ $t('service.calendar.all_day') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-form ref="formPrice" class="repeater-form mt-4" @submit.prevent="addPrice">
        <!--SELECTOR TYPE PRICE-->
        <price-global-fields class="mt-2" />

        <!-- price section Loop -->
        <template v-if="isHangars">
          <b-card ref="priceSection" class="border">
            <price-types v-model="priceRange.prices" />
          </b-card>
        </template>

        <template v-else>
          <b-card v-for="(pricing, index) in priceRange.hourPrices" :key="index" ref="priceSection" class="border">
            <!--time picker-->
            <div v-if="!dateConfig.allDay" class="mb-1 d-flex align-items-center">
              <div class="w-100">
                <b-form-timepicker
                  ref="startTime"
                  v-model="pricing.startHour"
                  :state="pricing.startHour < pricing.endHour"
                  :placeholder="$t('common.start_hour')"
                />
              </div>
              <div class="px-2">-</div>
              <div class="w-100">
                <b-form-timepicker ref="endTime" v-model="pricing.endHour" :state="pricing.startHour < pricing.endHour" :placeholder="$t('common.end_hour')" />
              </div>
            </div>

            <price-types v-model="priceRange.hourPrices[index].prices" />

            <b-row v-if="!dateConfig.allDay && priceRange.hourPrices.length > 1">
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button v-ripple.400 variant="flat-danger" class="btn-icon" @click="modalDeleteItem(index)">
                  <feather-icon v-b-tooltip.hover.bottom.v-danger size="21" icon="Trash2Icon" class="text-danger" :title="$t('action.delete')" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-form>

      <div v-if="!dateConfig.allDay" class="d-flex justify-content-end">
        <app-btn-add :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'" class="mb-4" @click="addPrice">{{ $t('service.calendar.add_new_hour') }}</app-btn-add>
      </div>

      <!-- Form Actions -->
      <b-row class="mb-4">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button v-ripple.400 class="mr-1" variant="outline-primary" @click="$emit('display-calendar', $event)">
            {{ $t('service.calendar.return_calendar') }}
          </b-button>

          <b-button :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'" v-if="priceRange.id" v-ripple.400 class="mr-1" variant="danger" @click="modalDeletePrice()">
            {{ $t('action.delete') }}
          </b-button>
          <b-button :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'" v-ripple.400 type="submit">
            {{ priceRange.id ? $t('action.edit') : $t('action.add') }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </section>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'

import { omit, pick } from 'lodash'

import PriceTypes from '@/views/product/component/PriceTypes.vue'
import AppBtnAdd from '@/components/AppBtnAdd.vue'
import PriceGlobalFields from './PriceGlobalFields.vue'

export default defineComponent({
  name: 'FormCalendar',

  components: {
    PriceTypes,
    AppBtnAdd,
    PriceGlobalFields,
  },

  props: {
    dateInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    eventInfo: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, ctx) {
    const { $i18n, toaster } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')
    const formCalendar = ref(null)

    const endConfig = ref('never')
    const weeksOccurrence = ref(0)
    const dateRepeat = ref('everyDays')
    const isHangars = ['hangar', 'parking', 'outside_ramps'].includes(product.value.typology)

    const dateRepeatOptions = [
      { value: 'everyDays', text: $i18n.t('service.calendar.repeat_every_days') },
      { value: 'custom', text: $i18n.t('service.calendar.repeat_custom') },
    ]
    const daysOfWeekOptions = [
      { value: 1, text: $i18n.t('common.monday') },
      { value: 2, text: $i18n.t('common.tuesday') },
      { value: 3, text: $i18n.t('common.wednesday') },
      { value: 4, text: $i18n.t('common.thursday') },
      { value: 5, text: $i18n.t('common.friday') },
      { value: 6, text: $i18n.t('common.saturday') },
      { value: 0, text: $i18n.t('common.sunday') },
    ]

    const dateConfig = ref({
      groupId: Math.floor(Math.random() * 9999),
      startTime: '',
      endTime: '',
      allDay: true,
      color: '#DCC181',
      textColor: 'white',
      extendedProps: {},
    })

    const priceRange = ref({
      id: null,
      startAt: null,
      endAt: null,
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      title: '',
      hourPrices: [
        {
          id: null,
          startHour: '00:00:00',
          endHour: '00:00:00',
          prices: [
            {
              id: null,
              fromQuantity: 1,
              toQuantity: null,
              fromQuantityUnit: 'hour',
              toQuantityUnit: 'hour',
              sellingPriceExcludingTaxes: 0,
              sellingPriceIncludingTaxes: 0,
              ecologicalTax: 0,
            },
          ],
        },
      ],
    })
    if (isHangars) {
      priceRange.value.prices = priceRange.value.hourPrices[0].prices
      delete priceRange.value.hourPrices
    }

    const onSubmit = async () => {
      const validForm = await formCalendar.value.validate()
      if (validForm) {
        const updatePriceCalendar = async () => {
          const action = priceRange.value.id ? 'patchPriceCalendar' : 'postPriceCalendar'

          await product.value[action](priceRange.value)
          setTimeout(() => {
            $emit('update-calendar')
            $emit('display-calendar')
          }, 10)
        }
        if (
          product.value.mainVariant.vatRate !== product.value._reference.mainVariant.vatRate
          || !product.value._reference.mainVariant.packing
          || product.value.mainVariant.packing.id !== product.value._reference.mainVariant.packing.id
          || product.value.mainVariant.pricingType !== product.value._reference.mainVariant.pricingType
        ) {
          product.value
            .patchPrice({
              ...pick(product.value.mainVariant, 'pricingType', 'visible', 'vatRate'),
              packingId: product.value.mainVariant.packing.id,
            })
            .then(async () => {
              const action = priceRange.value.id ? 'patchPriceCalendar' : 'postPriceCalendar'
              await product.value[action](priceRange.value)
              $emit('update-calendar')
              $emit('display-calendar')
            })
        } else {
          updatePriceCalendar()
        }
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    }

    return {
      product,
      isHangars,
      formCalendar,
      endConfig,
      weeksOccurrence,
      dateRepeat,
      dateRepeatOptions,
      daysOfWeekOptions,
      dateConfig,
      priceRange,
      onSubmit,
    }
  },

  methods: {
    initForm() {
      // UPDATE event Date
      if (this.eventInfo && this.eventInfo[0]) {
        this.dateConfig = Object.assign(this.dateConfig, this.eventInfo[0])
      }

      if (this.priceRange.hourPrices) {
        this.dateConfig.allDay = this.priceRange.hourPrices[0].startHour === this.priceRange.hourPrices[0].endHour
      }

      // RESET TO CLASSICAL STATE START/END date
      if (Object.prototype.hasOwnProperty.call(this.dateConfig, 'startRecur')) {
        this.priceRange.startAt = this.dateConfig.startRecur // Assign new key
        delete this.dateConfig.startRecur // Delete old key
      }
      if (Object.prototype.hasOwnProperty.call(this.dateConfig, 'endRecur')) {
        this.priceRange.endAt = this.dateConfig.endRecur // Assign new key
        delete this.dateConfig.endRecur // Delete old key
      }

      if (this.priceRange.endAt) {
        this.endConfig = 'end'
      } else {
        this.endConfig = 'never'
      }

      // SET repeat to custom if specifics day selected
      if (this.priceRange.daysOfWeek.length < 7 && this.priceRange.daysOfWeek.length) {
        this.dateRepeat = 'custom'
      } else {
        this.dateRepeat = 'everyDays'
      }

      // ADD a new event from selected date
      if (this.dateInfo && this.dateInfo.dateStr) {
        this.priceRange.startAt = this.dateInfo.dateStr
      }
    },
    // ADD price multi hours
    addPrice() {
      // Copy of previous price, and replace all ids by null
      const normalizedPrice = this._cloneDeep({
        ...this.priceRange.hourPrices[this.priceRange.hourPrices.length - 1],
        id: null,
      })
      normalizedPrice.prices = normalizedPrice.prices.map(price => ({ ...price, id: null }))
      this.priceRange.hourPrices.push(normalizedPrice)
    },
    alertTimeError() {
      this.alert(this.$t('alert.form.error'), 'alert.form.time_error')
    },
    modalDeleteItem(index) {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.delete_confirmation.message'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.priceRange.hourPrices.splice(index, 1)
          }
        })
    },
    modalDeletePrice() {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.delete_confirmation.message'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(ok => {
          if (ok) {
            this.product.deletePriceCalendar(this.priceRange.id).then(() => {
              const indexPR = this.product.mainVariant.priceRanges.findIndex(pr => this.priceRange.id === pr.id)
              const indexPRF = this.product.mainVariant.priceRangesFull.findIndex(pr => this.priceRange.id === pr.id)
              this.product.mainVariant.priceRanges.splice(indexPR, 1)
              this.product.mainVariant.priceRangesFull.splice(indexPRF, 1)
              this.$emit('display-calendar')
            })
          }
        })
    },
    switchAllDay(e) {
      if (e) {
        if (this.priceRange.hourPrices.length > 1) {
          this.modalDeleteHours()
        } else {
          this.priceRange.hourPrices[0].startHour = '00:00:00'
          this.priceRange.hourPrices[0].endHour = '00:00:00'
        }
      }
    },
    modalDeleteHours() {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.product.calendar.price.all_day'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(ok => {
          if (ok) {
            this.priceRange.hourPrices.splice(1, this.priceRange.hourPrices.length)
            this.priceRange.hourPrices[0].startHour = '00:00:00'
            this.priceRange.hourPrices[0].endHour = '00:00:00'
          } else {
            this.dateConfig.allDay = false
          }
        })
    },
  },
  activated() {
    const { rid } = this.$route.query
    if (!rid) {
      this.priceRange = {
        id: null,
        startAt: null,
        endAt: null,
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        title: '',
        hourPrices: [
          {
            id: null,
            startHour: '00:00:00',
            endHour: '00:00:00',
            prices: [
              {
                id: null,
                fromQuantity: 1,
                toQuantity: null,
                fromQuantityUnit: 'hour',
                toQuantityUnit: 'hour',
                sellingPriceExcludingTaxes: 0,
                sellingPriceIncludingTaxes: 0,
                ecologicalTax: 0,
              },
            ],
          },
        ],
      }
      if (this.isHangars) {
        this.priceRange.prices = this.priceRange.hourPrices[0].prices
        delete this.priceRange.hourPrices
      }
      this.initForm()
      return
    }

    let selectedPR = this.product.mainVariant.priceRangesFull.find(pr => pr.id.toString() === this.$route.query.rid)

    // For awaiting request...
    if (!selectedPR) {
      selectedPR = this.product.mainVariant.priceRanges.find(pr => pr.id.toString() === this.$route.query.rid)
    }
    if (selectedPR) {
      this.priceRange = omit(selectedPR, 'variant')
      if (this.priceRange.prices && !this.priceRange.prices[0]) {
        this.priceRange.prices.push({
          id: null,
          fromQuantity: 1,
          toQuantity: null,
          fromQuantityUnit: 'hour',
          toQuantityUnit: 'hour',
          sellingPriceExcludingTaxes: 0,
          sellingPriceIncludingTaxes: 0,
          ecologicalTax: 0,
        })
      }
      this.initForm()
    }

    this.product.fetchPriceCalendarById(rid).then(() => {
      const priceRangesFull = this.product.mainVariant.priceRangesFull.find(price => price.id.toString() === rid)
      this.priceRange = omit(priceRangesFull, 'variant')
      this.initForm()
    })
  },
})
</script>
